import React, { useState, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Flex from "components/Flex";
import Toggle from "components/Toggle";
import { currencyFormat } from "util/numbers";
import useStreamMarkPrice from "api/websockets/binanceFutures/useStreamMarkPrice";
import useStreamOrderbook from "api/websockets/binanceFutures/useStreamOrderbook";
import useStreamLatestPrice from "api/websockets/binanceSpot/useStreamLatestPrice";
import useSnapshotOrderbook from "hooks/useSnapshotOrderbook";
import useFormatOrderbook from "hooks/useFormatOrderbook";
import "./OrderbookChart.scss";

export const STATIC_GROUPING_BY_SYMBOL = {
	"BTC": 10,
	"ETH": 1,
};
const ORDER_COLORS = {
	'ask': 'rgba(245,54,92,0.35)',
	'bid': 'rgba(45,206,137,0.35)',
};
const MAX_LINES_OPS = [3,5,7,10,15,20,25];

function OrderbookChart(props) {
	const { symbol, marketType } = props;
	const [hide, setHide] = useState(true);
	const [maxLines, setMaxLines] = useState(MAX_LINES_OPS[4]);
	const [grouping, setGrouping] = useState(STATIC_GROUPING_BY_SYMBOL[symbol.toUpperCase()]);

	const {
		price: streamSpotPrice,
		prevPrice: streamSpotPrevPrice
	} = useStreamLatestPrice(marketType === 'spot' ? {
		symbol: `${symbol}USDT`,
		frequency: 1000,
	} : {});

	const {
		price: streamFuturesPrice,
		prevPrice: streamFuturesPrevPrice
	} = useStreamMarkPrice(marketType === 'futures' ? `${symbol}USDT` : '');

	const [
		price = 0,
		prevPrice = 0
	] = useMemo(() => {
		return marketType === 'futures' ? [
			streamFuturesPrice,
			streamFuturesPrevPrice
		] : [
			streamSpotPrice,
			streamSpotPrevPrice,
		];
	}, [
		marketType,
		streamSpotPrice,
		streamSpotPrevPrice,
		streamFuturesPrice,
		streamFuturesPrevPrice,
	]);

	const {
		asks: futuresAsks = {},
		bids: futuresBids = {},
	} = useStreamOrderbook(hide || marketType === 'spot' ? undefined : `${symbol}USDT`);

	const {
		snapshot: spotSnapshot = [],
	} = useSnapshotOrderbook({
		symbol: marketType === 'spot' ? `${symbol}USDT` : '',
		frequency: 500,
		onlyLatest: true,
		marketType: 'spot',
	});

	const [
		asks = {},
		bids = {},
	] = useMemo(() => {
		return marketType === 'futures' ? [
			futuresAsks,
			futuresBids,
		] : [
			spotSnapshot?.asks?.reduce(
				(acc, [price, qty]) => {
					acc[Number(price)] = Number(qty);
					return acc;
				}, {}
			),
			spotSnapshot?.bids?.reduce(
				(acc, [price, qty]) => {
					acc[Number(price)] = Number(qty);
					return acc;
				}, {}
			),
		]
	// eslint-disable-next-line
	}, [
		marketType,
		futuresAsks,
		futuresBids,
		// eslint-disable-next-line
		JSON.stringify(spotSnapshot),
	]);

	const {
		asks: descendingAsks = [],
		bids: descendingBids = [],
		minAskPrice,
		maxAskPrice,
		minBidPrice,
		maxBidPrice,
		// minAskQty,
		maxAskQty,
		// minBidQty,
		maxBidQty,
		// minPrice,
		// maxPrice,
		// minQty,
		maxQty,
		// totalAsksQty,
		// totalBidsQty,
		totalAsksInUsd,
		totalBidsInUsd,
	} = useFormatOrderbook({
		asks,
		bids,
		maxLines,
		grouping,
	});

	return (
		<>
			<Options
				symbol={symbol}
				maxLines={maxLines}
				setMaxLines={setMaxLines}
				grouping={grouping}
				setGrouping={setGrouping}
			/>
			<Card className="OrderbookChart shadow">
				<Card.Header>
					<Flex
						justify="between"
						align="center"
					>
						<span>Orderbook Chart</span>
						<Button
							size="xs"
							variant={hide ? 'primary' : 'danger'}
							onClick={() => setHide(prev => !prev)}
						>
							{hide ? 'Show' : 'Hide'}
						</Button>
					</Flex>
				</Card.Header>
				{!hide && (
					<Card.Body className="px-3 py-2">
						<OrderbookBarChart
							orders={descendingAsks}
							minPrice={minAskPrice}
							maxPrice={maxAskPrice}
							maxQty={maxAskQty}
							maxAllQty={maxQty}
							side="ask"
						/>
						<Flex
							justify="between"
							align="center"
							className="currentPrice px-1 py-2 my-2 border-bottom border-top"
						>
							<Flex
								direction="column"
								align="start"
								className="f-rem-0.75"
							>
								<span className="text-danger fw-6">Asks:</span>
								<span className={clsx({
									'fw-7': totalAsksInUsd >= totalBidsInUsd,
									'text-muted': totalAsksInUsd < totalBidsInUsd,
								})}>
									{currencyFormat(totalAsksInUsd)}
								</span>
							</Flex>
							<Flex
								align="center"
							>
								<span className="price">{currencyFormat(price)}</span>
								<FontAwesomeIcon
									icon={faCaretDown}
									rotation={prevPrice > price ? 0 : 180}
									className={clsx('icon', {
										'down': prevPrice > price,
										'up': price > prevPrice,
									})}
								/>
							</Flex>
							<Flex
								direction="column"
								align="end"
								className="f-rem-0.75"
							>
								<span className="text-success fw-5">Bids:</span>
								<span className={clsx({
									'fw-7': totalBidsInUsd >= totalAsksInUsd,
									'text-muted': totalBidsInUsd < totalAsksInUsd,
								})}>
									{currencyFormat(totalBidsInUsd)}
								</span>
							</Flex>
						</Flex>
						<OrderbookBarChart
							orders={descendingBids}
							minPrice={minBidPrice}
							maxPrice={maxBidPrice}
							maxQty={maxBidQty}
							maxAllQty={maxQty}
							side="bid"
						/>
					</Card.Body>
				)}
			</Card>
		</>
	);
};

export default OrderbookChart;

export const Options = ({symbol, maxLines, setMaxLines, grouping, setGrouping}) => {
	const groupingRef = useRef();

	useEffect(() => {
		const newGrouping = STATIC_GROUPING_BY_SYMBOL[symbol.toUpperCase()];
		setGrouping(newGrouping);
		groupingRef.current.value = newGrouping;
	}, [symbol, setGrouping]);

	return (
		<Flex
			justify="between"
			align="center"
			className="Options mb-2"
		>
			<Toggle
				ops={MAX_LINES_OPS}
				active={maxLines}
				setActive={setMaxLines}
			/>

			<InputGroup
				size="sm"
				className="w-fit"
			>
				<InputGroup.Prepend className="border-left">
		      <InputGroup.Text id="inputGroup-sizing-sm">Group</InputGroup.Text>
		    </InputGroup.Prepend>
				<FormControl
					ref={groupingRef}
					aria-label="Group"
					aria-describedby="inputGroup-sizing-sm"
					defaultValue={grouping}
					onChange={e => {
						const asInt = Number(e.target.value);

						if (!!asInt && !isNaN(asInt)) {
							setGrouping(asInt);
						}
					}}
					style={{maxWidth: '56px'}}
				/>
			</InputGroup>
		</Flex>
	);
};

const OrderbookBarChart = ({orders = [], minPrice, maxPrice, maxQty, maxAllQty, side = 'ask'}) => {
	return (
		<Flex
			direction="column"
			className="OrderbookBarChart"
		>
			{orders.map(order => (
				<Flex
					key={order?.price}
					justify="between"
					className="row-wrapper px-1"
				>
					<div
						className="bar"
						style={{
							width: `${(order?.qty)/(maxAllQty)*100}%`,
							backgroundColor: ORDER_COLORS[side],
						}}
					></div>
					<span
						className={clsx('price', {
							'largest-qty': maxQty === order?.qty
						})}
					>
						{currencyFormat(order?.price)}
					</span>
					<span
						className="qty"
					>
						<small className="text-muted">{currencyFormat(order?.price * order?.qty)}</small>
					</span>
				</Flex>
			))}
		</Flex>
	);
};
