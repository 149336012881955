import supabase from 'util/supabase';
import { useState, useEffect, useMemo } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { bingxApiUri, binanceFuturesUri } from 'api/client/constants';
import { DEFAULT_OPTIONS } from 'api/websockets/constants';
import { wsUrl } from './constants';

function useStreamOrderbook(symbol) {
	const [socketUrl, setSocketUrl] = useState(wsUrl);
	const [snapshot, setSnapshot] = useState({});
	const [asks, setAsks] = useState({});
	const [bids, setBids] = useState({});
	const [timestamp, setTimestamp] = useState(0);
	const { lastMessage, readyState } = useWebSocket(socketUrl, DEFAULT_OPTIONS('useStreamOrderbook'));

	useEffect(() => {
		if (symbol) {
			setSocketUrl(wsUrl);

			fetch(
				`${bingxApiUri}/proxy/toronto?` + new URLSearchParams({
					method: 'GET',
					url: `${binanceFuturesUri}/fapi/v1/depth?symbol=${symbol.toUpperCase()}&limit=1000`,
				}), {
					headers: {
						'Cache-Control': 'no-cache',
						'Pragma': 'no-cache',
						'Expires': '0',
						'authorization': supabase.auth.session()?.access_token,
					},
				}
			).then(
				res => res.json()
			).then(
				res => {
					const {
						E: messageOutputTime,
						T: transactionTime,
						lastUpdateId,
						asks = [],
						bids = [],
					} = res;

					const newSnapshot = {
						messageOutputTime,
						transactionTime,
						lastUpdateId,
						asks: asks.reduce(
							(acc, [price, qty]) => {
								acc[price] = Number(qty);
								return acc;
							}, {}
						),
						bids: bids.reduce(
							(acc, [price, qty]) => {
								acc[price] = Number(qty);
								return acc;
							}, {}
						),
					};

					setAsks(newSnapshot.asks);
					setBids(newSnapshot.bids);
					setSnapshot(newSnapshot);

					setSocketUrl(`${wsUrl}${symbol.toLowerCase()}@depth@500ms`);
				}
			).catch(
				err => console.error('Error @ useStreamOrderbook.js -> fetch snapshot', err)
			);
		} else {
			setSocketUrl(wsUrl);
		}
	}, [symbol]);

	const lastUpdateId = useMemo(() => snapshot?.lastUpdateId, [snapshot?.lastUpdateId]);

	useEffect(() => {
		if (lastMessage?.data) {
			const {
				// e: eventType,
				// E: eventTime,
				// T: transactionTime,
				// s: symbol,
				// U: firstUpdateIdInEvent,
				u: finalUpdateIdInEvent,
				// pu: finalUpdateIdInLastStream, //u in last stream
				b: bidsToUpdate = [], //[ "priceLevelToUpdate", "quantity" ]
				a: asksToUpdate = [], //[ "priceLevelToUpdate", "quantity" ]
			} = JSON.parse(lastMessage?.data);

			if (
				lastUpdateId &&
				finalUpdateIdInEvent >= lastUpdateId
			) {
				setAsks(prev => {
					const prevAsks = { ...prev };

					asksToUpdate.forEach(
						([price, qty]) => {
							if (!Number(qty)) {
								delete prevAsks[price];
							} else {
								prevAsks[price] = Number(qty);
							}
						}
					);

					return prevAsks;
				});

				setBids(prev => {
					const prevBids = { ...prev };

					bidsToUpdate.forEach(
						([price, qty]) => {
							if (!Number(qty)) {
								delete prevBids[price];
							} else {
								prevBids[price] = Number(qty);
							}
						}
					);

					return prevBids;
				});

				setTimestamp(new Date().getTime());
			}
		}
	}, [lastMessage, lastUpdateId]);

	return {
		timestamp,
		asks,
		bids,
		readyState: ReadyState[readyState],
	};
};

export default useStreamOrderbook;
